import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  Divider,
  Typography,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getDefaultDate } from "../../utils/shared";
import BidGrid, { GridRow } from "./BidGrid";
import {
  TransformedData
} from "./types";
import { set } from "date-fns";

// Load required plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// selection options
const SCOptions = ["GUZC", "GUZM"];
const PrimaryInterTieIDOptions: { [key: string]: string[] } = {
  AMARGOSA230: ["AMARGOSA_1_SN001"],
  BLYTHE161: ["BLYTHE_1_N101"],
  CFEROA: ["ROA-230_2_N101"],
  CFETIJ: ["TJI-230_2_N101"],
  CRAG: ["CRAGVIEW_1_GN001"],
  CTW230: ["CAPTJACK_5_N510", "SMD5_ASR-APND"],
  DELANEY500: ["DLN_5_GNODEM1"],
  ELDORADO230: ["MAGNOLIA_2_N008"],
  ELDORADO500: ["MCCULLGH_5_N101"],
  GONIPP: ["GONDER_2_N501"],
  HA500: ["HALLEN_5_SN001"],
  IPP: ["INTERM1G_7_N501"],
  IPPUTAH: ["INTERMT_3_N506"],
  IVLY2: ["ELCENTRO_2_N001"],
  LAKE: ["CAPTJACK_5_N508", "SMD7_ASR-APND"],
  LLL115: ["CAPTJACK_5_N509", "SMD6_ASR-APND"],
  LUGO: ["VICTORVL_5_N101"],
  MALIN500: ["MALIN_5_N101"],
  MARBLE60: ["MARBLSPP_6_N001"],
  MARKETPLACE: ["MARKETPL_5_N501"],
  MCCULLOUG500: ["MCCULLGX_5_N501"],
  MDWP: ["MONA_3_N501"],
  MEAD230: ["MEADS_2_N101"],
  MEAD2MSCHD: ["MEADN_2_N501"],
  MEAD5MSCHD: ["MEAD_5_N501"],
  MERCURY138: ["MERCURY_1_N008"],
  MIR2: ["COACHELV_2_N101", "RAMON_2_N001"],
  MOHAVE500: ["MOH-LGHN_5_N001"],
  NOB: ["SYLMARDC_2_N501"],
  NRTHGILA500: ["NGILAN_5_N034"],
  NWEST: ["NWEST_2_SN001"],
  OAKDALE: ["OAKDLTID_1_N001"],
  PARKER230: ["PARKER_2_N101"],
  PVWEST: ["PALOVRDE_ASR-APND"],
  RANCHOSECO: ["CAPTJACK_5_N507", "SMD8_ASR-APND"],
  RDM230: ["CAPTJACK_5_N511", "SMD4_ASR-APND"],
  SILVERPEAK55: ["SLVRPS2_7_N001"],
  STANDIFORD: ["STANDFD2_1_N011"],
  SUMMIT120: ["SUMMIT_ASR-APND"],
  SYLMAR: ["SYLMARLA_2_N501"],
  TESLA230: ["CAPTJACK_5_N506", "SMD9_ASR-APND"],
  TRCYCOTP: ["CAPTJACK_5_N015", "SMDG_ASR-APND"],
  TRCYCOTPISO: ["CAPTJACK_5_N512", "SMDH_ASR-APND"],
  TRCYPGAE: ["CAPTJACK_5_N505", "SMDA_ASR-APND"],
  TRCYTEA: ["CAPTJACK_5_N513"],
  WESTLYFINK: ["CAPTJACK_5_N003", "SMDC_ASR-APND"],
  WESTLYTSLA: ["CAPTJACK_5_N504", "SMDB_ASR-APND"],
  WESTWING500: ["WESTWING_5_N501"],
  WILLOWBEACH: ["WILOWBCH_6_ND001"],
};
const EnergyTypeOptions = ["FIRM", "NFIRM", "DYN", "WHEEL", "UC"];
const TransactionOptions = ["Import", "Export"];
const PSEOptions = ["GPM", "GPM.1", "GPM.2"];
const MarketTypeOptions = ["DAM", "RTM"];

export type BidData = {
  name: string;
  marketType: string;
  marketProductType: string;
  mridSC: string;
  timeIntervalStart: string;
  timeIntervalEnd: string;
  resourceBids: GridRow[];
};

interface BidMainHeaderProps {
  onSubmit?: (data: BidData) => void;
  initData?: TransformedData;
  onBack?: () => void;
}

const BidMainHeader: React.FC<BidMainHeaderProps> = ({
  onSubmit = () => {},
  initData,
  onBack = () => {}
}) => {
  const [dateTime, setDateTime] = useState<Date | null>(getDefaultDate(1));
  const [SC, setSC] = useState<string>("");
  const [PrimaryInterTieID, setPrimaryInterTieID] = useState<string>("");
  const [EnergyType, setEnergyType] = useState<string>("");
  const [Location, setLocation] = useState<string>("");
  const [Transaction, setTransaction] = useState<string>("");
  const [PSE, setPSE] = useState<string>("");
  const [MarketType, setMarketType] = useState<string>("");
  const [ResourceName, setResourceName] = useState<string>("");
  const [ResourceNameArray, setResourceNameArray] = useState<string[]>([]);

  // New fields for WHEEL energy type
  const [WheelSC, setWheelSC] = useState<string>("");
  const [WheelPrimaryInterTieID, setWheelPrimaryInterTieID] =
    useState<string>("");
  const [WheelLocation, setWheelLocation] = useState<string>("");
  const [WheelTransaction, setWheelTransaction] = useState<string>("");
  const [WheelEnergyType, setWheelEnergyType] = useState<string>("");
  const [WheelPSE, setWheelPSE] = useState<string>("");
  const [WheelResourceName, setWheelResourceName] = useState<string>("");
  const [WheelResourceNameArray, setWheelResourceNameArray] = useState<
    string[]
  >([]);

  const handleDateTimeChange = (date: Date | null) => {
    setDateTime(date);
  };

  const updateResourceName = () => {
    if (SC && Location && PrimaryInterTieID && Transaction && PSE) {
      const ResName = `${SC}-${Location}-${PrimaryInterTieID}-${Transaction[0]}-P-${EnergyType[0]}-${PSE}`;
      setResourceName(ResName);
      setResourceNameArray([
        SC,
        Location,
        PrimaryInterTieID,
        Transaction[0],
        "P",
        EnergyType[0],
        PSE,
      ]);
    } else {
      setResourceName("");
      setResourceNameArray([]);
    }
  };

  const updateWheelResourceName = () => {
    if (
      WheelSC &&
      WheelLocation &&
      WheelPrimaryInterTieID &&
      WheelTransaction &&
      WheelPSE
    ) {
      const WheelResName = `${WheelSC}-${WheelLocation}-${WheelPrimaryInterTieID}-${WheelTransaction[0]}-P-${WheelEnergyType[0]}-${WheelPSE}`;
      setWheelResourceName(WheelResName);
      setWheelResourceNameArray([
        WheelSC,
        WheelLocation,
        WheelPrimaryInterTieID,
        WheelTransaction[0],
        "P",
        WheelEnergyType[0],
        WheelPSE,
      ]);
    } else {
      setWheelResourceName("");
      setWheelResourceNameArray([]);
    }
  };

  useEffect(() => {
    if (initData && initData.nameArray && initData.nameArray.length >= 7) {
      setSC(initData.nameArray[0]);
      setLocation(initData.nameArray[1]);
      setPrimaryInterTieID(initData.nameArray[2]);
      setTransaction(initData.nameArray[3] === 'I' ? 'Import' : 'Export');
  
      const energyTypeLetter = initData.nameArray[5];
      let fullEnergyType = '';
      if (energyTypeLetter === 'F') fullEnergyType = 'FIRM';
      else if (energyTypeLetter === 'N') fullEnergyType = 'NFIRM';
      else if (energyTypeLetter === 'D') fullEnergyType = 'DYN';
      else if (energyTypeLetter === 'W') fullEnergyType = 'WHEEL';
      else if (energyTypeLetter === 'U') fullEnergyType = 'UC';
  
      setEnergyType(fullEnergyType);
      setPSE(initData.nameArray[6]);
      setMarketType(initData.marketType);
      if (initData.tradeDate) {
        const tradeDate = dayjs(initData.tradeDate).toDate();
        setDateTime(tradeDate);
      }
    }
    
    if(initData && initData.resourceBids && initData.resourceBids.length > 0) {
      let wheelReferenceArray: string[] | null = null;

      for (let i = 0; i < initData.resourceBids.length; i++) {
        const bid = initData.resourceBids[i];
        if (bid.wheelReferenceArray) {
          wheelReferenceArray = bid.wheelReferenceArray;
          break;
        }
      }

      if (wheelReferenceArray && wheelReferenceArray.length >= 7) {
        setWheelSC(wheelReferenceArray[0]);
        setWheelLocation(wheelReferenceArray[1]);
        setWheelPrimaryInterTieID(wheelReferenceArray[2]);
        setWheelTransaction(wheelReferenceArray[3] === 'I' ? 'Import' : 'Export');
          
        const wheelEnergyTypeLetter = wheelReferenceArray[5];
        let wheelFullEnergyType = '';
        if (wheelEnergyTypeLetter === 'F') wheelFullEnergyType = 'FIRM';
        else if (wheelEnergyTypeLetter === 'N') wheelFullEnergyType = 'NFIRM';
        else if (wheelEnergyTypeLetter === 'D') wheelFullEnergyType = 'DYN';
        else if (wheelEnergyTypeLetter === 'W') wheelFullEnergyType = 'WHEEL';
        else if (wheelEnergyTypeLetter === 'U') wheelFullEnergyType = 'UC';
          
        setWheelEnergyType(wheelFullEnergyType);
        setWheelPSE(wheelReferenceArray[6]);
      } else if (initData.resourceBids.some(bid => bid.wheelReference)) {
        alert("Invalid wheel reference array format in the data");
      }
    }

  }, [initData]);


  useEffect(() => {
    updateResourceName();
  }, [SC, Location, PrimaryInterTieID, Transaction, PSE, EnergyType]);

  useEffect(() => {
    updateWheelResourceName();
  }, [
    WheelSC,
    WheelLocation,
    WheelPrimaryInterTieID,
    WheelTransaction,
    WheelPSE,
    WheelEnergyType,
  ]);

  const handleSCChange = (value: string) => {
    setSC(value);
    // Reset dependent fields
    setPrimaryInterTieID("");
    setLocation("");
  };

  const handlePrimaryInterTieIDChange = (value: string) => {
    setPrimaryInterTieID(value);
    // Reset Location if it's not in the new options
    if (!PrimaryInterTieIDOptions[value].includes(Location)) {
      setLocation("");
    }
  };

  const handleWheelSCChange = (value: string) => {
    setWheelSC(value);
    // Reset dependent fields
    setWheelPrimaryInterTieID("");
    setWheelLocation("");
  };

  const handleWheelPrimaryInterTieIDChange = (value: string) => {
    setWheelPrimaryInterTieID(value);
    // Reset WheelLocation if it's not in the new options
    if (!PrimaryInterTieIDOptions[value].includes(WheelLocation)) {
      setWheelLocation("");
    }
  };

  const areAllFieldsFilled = () => {
    return SC && Location && PrimaryInterTieID && Transaction && PSE;
  };

  const areAllWheelFieldsFilled = () => {
    return (
      WheelSC &&
      WheelLocation &&
      WheelPrimaryInterTieID &&
      WheelTransaction &&
      WheelPSE
    );
  };

  const handleDataChange = (data: any) => {
    // Handle data changes from BidGrid if needed
    // console.log("Data changed:", data);
  };

  const handleOnDataGridSubmit = (rows: GridRow[]) => {
    // Handle data submission from BidGrid
    // console.log("Data submitted:", rows);

    if (!dateTime) {
      alert("Please select a date!");
      return;
    }

    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, "0");
    const day = String(dateTime.getDate()).padStart(2, "0");
    const pptOffset = dayjs(dateTime).tz('America/Los_Angeles').format('Z');

    const data = {
      name: ResourceName,
      nameArray: ResourceNameArray,
      marketType: MarketType,
      marketProductType: EnergyType,
      mridSC: SC,
      timeIntervalStart: `${year}-${month}-${day}T00:00:00${pptOffset}`,
      timeIntervalEnd: `${year}-${month}-${day}T01:00:00${pptOffset}`,
      resourceBids: rows,
    };

    onSubmit(data);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
     <Box sx={{ mb: 3, mt: -2 }}>
       <Button variant="outlined" onClick={onBack}>
         Back to Summary
       </Button>
     </Box>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <DatePicker
              label="DateTime"
              onChange={handleDateTimeChange}
              value={dateTime}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="SC-select-label">SC</InputLabel>
            <Select
              labelId="SC-select-label"
              id="SC-select"
              value={SC}
              label="SC"
              onChange={(e) => handleSCChange(e.target.value)}
            >
              {SCOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="PrimaryInterTieID-select-label">
              Primary Inter Tie ID
            </InputLabel>
            <Select
              labelId="PrimaryInterTieID-select-label"
              id="PrimaryInterTieID-select"
              value={PrimaryInterTieID}
              label="Primary Inter Tie ID"
              onChange={(e) => handlePrimaryInterTieIDChange(e.target.value)}
            >
              {Object.keys(PrimaryInterTieIDOptions).map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="EnergyType-select-label">Energy Type</InputLabel>
            <Select
              labelId="EnergyType-select-label"
              id="EnergyType-select"
              value={EnergyType}
              label="Energy Type"
              onChange={(e) => setEnergyType(e.target.value)}
            >
              {EnergyTypeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="Location-select-label">Location</InputLabel>
            <Select
              labelId="Location-select-label"
              id="Location-select"
              value={Location}
              label="Location"
              onChange={(e) => setLocation(e.target.value)}
            >
              {PrimaryInterTieID &&
                PrimaryInterTieIDOptions[PrimaryInterTieID].map((key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="Transaction-select-label">Transaction</InputLabel>
            <Select
              labelId="Transaction-select-label"
              id="Transaction-select"
              value={Transaction}
              label="Transaction"
              onChange={(e) => setTransaction(e.target.value)}
            >
              {TransactionOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="PSE-select-label">PSE</InputLabel>
            <Select
              labelId="PSE-select-label"
              id="PSE-select"
              value={PSE}
              label="PSE"
              onChange={(e) => setPSE(e.target.value)}
            >
              {PSEOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="MarketType-select-label">Market Type</InputLabel>
            <Select
              labelId="MarketType-select-label"
              id="MarketType-select"
              value={MarketType}
              label="Market Type"
              onChange={(e) => setMarketType(e.target.value)}
            >
              {MarketTypeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {areAllFieldsFilled() && (
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                id="ResourceName"
                label="Resource Name"
                value={ResourceName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          </Grid>
        )}

        {EnergyType === "WHEEL" && (
          <>
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }}>
                <Typography
                  variant="h6"
                  component="span"
                  sx={{ px: 2, fontWeight: "bold" }}
                >
                  Wheel Information
                </Typography>
              </Divider>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="WheelSC-select-label">Wheel SC</InputLabel>
                <Select
                  labelId="WheelSC-select-label"
                  id="WheelSC-select"
                  value={WheelSC}
                  label="Wheel SC"
                  onChange={(e) => handleWheelSCChange(e.target.value)}
                >
                  {SCOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="WheelPrimaryInterTieID-select-label">
                  Wheel Primary Inter Tie ID
                </InputLabel>
                <Select
                  labelId="WheelPrimaryInterTieID-select-label"
                  id="WheelPrimaryInterTieID-select"
                  value={WheelPrimaryInterTieID}
                  label="Wheel Primary Inter Tie ID"
                  onChange={(e) =>
                    handleWheelPrimaryInterTieIDChange(e.target.value)
                  }
                >
                  {Object.keys(PrimaryInterTieIDOptions).map((key) => (
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="WheelLocation-select-label">
                  Wheel Location
                </InputLabel>
                <Select
                  labelId="WheelLocation-select-label"
                  id="WheelLocation-select"
                  value={WheelLocation}
                  label="Wheel Location"
                  onChange={(e) => setWheelLocation(e.target.value)}
                >
                  {WheelPrimaryInterTieID &&
                    PrimaryInterTieIDOptions[WheelPrimaryInterTieID].map(
                      (key) => (
                        <MenuItem key={key} value={key}>
                          {key}
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="WheelTransaction-select-label">
                  Wheel Transaction
                </InputLabel>
                <Select
                  labelId="WheelTransaction-select-label"
                  id="WheelTransaction-select"
                  value={WheelTransaction}
                  label="Wheel Transaction"
                  onChange={(e) => setWheelTransaction(e.target.value)}
                >
                  {TransactionOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="WheelEnergyType-select-label">
                  Wheel Energy Type
                </InputLabel>
                <Select
                  labelId="WheelEnergyType-select-label"
                  id="WheelEnergyType-select"
                  value={WheelEnergyType}
                  label="Wheel Energy Type"
                  onChange={(e) => setWheelEnergyType(e.target.value)}
                >
                  {EnergyTypeOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="WheelPSE-select-label">Wheel PSE</InputLabel>
                <Select
                  labelId="WheelPSE-select-label"
                  id="WheelPSE-select"
                  value={WheelPSE}
                  label="Wheel PSE"
                  onChange={(e) => setWheelPSE(e.target.value)}
                >
                  {PSEOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {areAllWheelFieldsFilled() && (
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <TextField
                    id="WheelResourceName"
                    label="Wheel Resource Name"
                    value={WheelResourceName}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <BidGrid
        onDataChange={handleDataChange}
        onSubmit={handleOnDataGridSubmit}
        marketType={MarketType}
        wheelReference={
          EnergyType === "WHEEL" ? WheelResourceName || null : undefined
        }
        wheelReferenceArray={
          EnergyType === "WHEEL" ? WheelResourceNameArray || null : undefined
        }
        transaction={Transaction}
        PrimaryInterTieID={PrimaryInterTieID}
        dateTime={dateTime}
        initData={initData}
      />
    </Box>
  );
};

export default BidMainHeader;
