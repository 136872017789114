import React, {useState, useEffect} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import BidMainHeader, { BidData } from "./BidMainHeader";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Box, CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import fetchGCP from "../../utils/fetchGCP";
import {
  IntervalData,
  ResourceBid,
  TransformedData,
  SubmittedData,
  responseData,
} from "./types";
import { exec } from "child_process";


function extractSelfScheduleValue(self_sched_str: string): {
  selfScheduleValue: number | undefined;
  wheelReference: string | undefined;
} | undefined {
  try{
    // replace all ' in the string with "
    const processed_self_sched_data = self_sched_str.replace(/'/g, '"');
    const self_sched_data = JSON.parse(processed_self_sched_data);
    if (self_sched_data && self_sched_data.length > 0) {
      const v = self_sched_data[0].selfSchedMw;
      const v_n = parseFloat(v);

      if(!isNaN(v_n)) {
        return {
          selfScheduleValue: v_n,
          wheelReference: undefined
        }
      }

      return {
        selfScheduleValue: undefined,
        wheelReference: v
      }
    }
  } catch (e) {
    console.error(`Error parsing self_sched_data when process:`, e);
  }
}


function processData(input: responseData): TransformedData[] {

  console.log("input", input);

  // Group by resource
  const resourcesMap: Record<string, IntervalData[]> = {};
  const resourcesMapSubmittedData: Record<string, 
    Record<number, SubmittedData>
  > = {};

  for (let interval of input) {

    // If it has "RegosteredInterTie_mrid" instead of "resource", it's a submitted data item
    if ((interval as SubmittedData).RegisteredInterTie_mrid) {
      const submittedData = interval as SubmittedData;
      const key = submittedData.RegisteredInterTie_mrid + '_' + submittedData.marketType;
      if (!resourcesMapSubmittedData[key]) {
        resourcesMapSubmittedData[key] = [];
      }
      
      const hr_num = typeof submittedData.HR === "string" ? parseInt(submittedData.HR, 10) : submittedData.HR;

      if(resourcesMapSubmittedData[key][hr_num]) {

        const d1 = resourcesMapSubmittedData[key][hr_num];
        const d2 = submittedData;

        const s1 = extractSelfScheduleValue(d1.self_sched_data);
        const s2 = extractSelfScheduleValue(d2.self_sched_data);

        const selfScheduleValue = s1?.selfScheduleValue || s2?.selfScheduleValue;
        const wheelReference = s1?.wheelReference || s2?.wheelReference;

        if(selfScheduleValue === undefined || wheelReference === undefined) {
          console.error("Error merging self schedule data:", d1, d2);
          continue;
        }

        resourcesMapSubmittedData[key][hr_num].wheel = {
          selfScheduleValue,
          wheelReference
        }

      } else {
        resourcesMapSubmittedData[key][hr_num] = submittedData;
      }

      continue;
    }

    // If it has "resource" instead of "RegosteredInterTie_mrid", it's an interval data item
    interval = interval as IntervalData;

    const key = interval.resource + '_' + interval.marketType; // combine resource and marketType as a unique key
    if (!resourcesMap[key]) {
      resourcesMap[key] = [];
    }
    resourcesMap[key].push(interval);
  }

  console.log("resourcesMap", resourcesMap);
  console.log("resourcesMapSubmittedData", resourcesMapSubmittedData);

  const result: TransformedData[] = [];

  for (const combinedKey in resourcesMap) {
    const intervals = resourcesMap[combinedKey];
    // Assume marketType and tradeDate are consistent for a given resource
    const marketType = intervals[0].marketType;
    const tradeDate = intervals[0].tradeDate;
    const resourceType = intervals[0].resourceType;

    // Extract fields from one of the intervals (they should be consistent across intervals of the same resource)
    const {
      schedulingCoordinator,
      location,
      schedulingPoint,
      direction,
      energyType,
      resource
    } = intervals[0];

    // The last part of the nameArray is after the last '-'
    const lastDashIndex = resource.lastIndexOf('-');
    const lastValue = lastDashIndex !== -1 ? resource.substring(lastDashIndex + 1) : resource;

    // Construct the nameArray using fields:
    // According to the instructions:
    // 0: schedulingCoordinator
    // 1: location
    // 2: schedulingPoint
    // 3: direction
    // 4: "P" (a fixed letter)
    // 5: first letter of energyType in uppercase
    // 6: the last word after the last '-'
    const nameArray = [
      schedulingCoordinator,
      location,
      schedulingPoint,
      direction,
      "P", // fixed
      energyType.charAt(0).toUpperCase(),
      lastValue
    ];

    // Group intervals by HR
    const hrMap: Record<number, IntervalData[]> = {};
    for (const interval of intervals) {
      if (!hrMap[interval.HR]) {
        hrMap[interval.HR] = [];
      }
      hrMap[interval.HR].push(interval);
    }

    const resourceBids: ResourceBid[] = [];

    for (const hrStr in hrMap) {
      const hr = parseInt(hrStr, 10);
      const hrIntervals = hrMap[hr];

      // Sort by intervalNum to ensure the order is correct
      hrIntervals.sort((a, b) => a.intervalNum - b.intervalNum);

      // MARKET intervals for awardedBid and lmp
      const marketIntervals = hrIntervals.filter(i => i.scheduleType === "MARKET");
      // SELF intervals for awardedSelfSchedule
      const selfIntervals = hrIntervals.filter(i => i.scheduleType === "SELF");

      // Extract arrays
      const awardedBid = marketIntervals.map(i => i.MW);
      const awardedSelfSchedule = selfIntervals.map(i => i.MW);
      const lmp = marketIntervals.map(i => i.price);

      const key = resource + '_' + marketType;
      const submittedData = (resourcesMapSubmittedData[key] && resourcesMapSubmittedData[key][hr]) || undefined;

      let bidSelfSchedule = undefined;
      let bidPriceCurve = undefined;
      let wheelReference = undefined;

      if(submittedData) {
        if(submittedData.wheel) {
          const wheel = submittedData.wheel;
          bidSelfSchedule = wheel.selfScheduleValue;
          wheelReference = wheel.wheelReference;
        } else {
          try{
            // replace all ' in the string with "
            const processed_self_sched_data = submittedData.self_sched_data.replace(/'/g, '"');
            const self_sched_data = JSON.parse(processed_self_sched_data);
            if (self_sched_data.length > 0) {
              bidSelfSchedule = parseFloat(self_sched_data[0].selfSchedMw);
            }
          } catch (e) {
            console.error("Error parsing self_sched_data:", e);
          }
        }

        // bid_price_curve looks like this:
        // [{'timeIntervalEnd': '2025-01-08T03:00:00.000-00:00', 'timeIntervalStart': '2025-01-08T02:00:00.000-00:00', 'mw': '0', 'price': '1.00'}, {'timeIntervalEnd': '2025-01-08T03:00:00.000-00:00', 'timeIntervalStart': '2025-01-08T02:00:00.000-00:00', 'mw': '1', 'price': '1.00'}]

        if(submittedData.bid_price_curve && submittedData.bid_price_curve.length > 0) {
          try {
            const processed_big_price_curve = submittedData.bid_price_curve.replace(/'/g, '"');
            bidPriceCurve = JSON.parse(processed_big_price_curve);
          } catch (e) {
            console.error("Error parsing bid_price_curve:", e);
          }
        }
      }

      // console.log("submittedData", submittedData, "bidSelfSchedule", bidSelfSchedule);

      // If there's a scenario with no MARKET or SELF data, we could handle that,
      // but based on the example, we assume data consistency.
      const resourceBid: ResourceBid = {
        id: hr,
        hour: hr,
        awardedBid: awardedBid.length ? awardedBid : Array(hrIntervals.length).fill(0),
        awardedSelfSchedule: awardedSelfSchedule.length ? awardedSelfSchedule : Array(hrIntervals.length).fill(0),
        lmp: lmp.length ? lmp : Array(hrIntervals.length).fill(0),
        // segment1MW: 0,
        status: submittedData?.bidStatus,
        errors: submittedData?.errors,
        bidOption: submittedData?.resourceBidOption,
        bidSelfScheduleSIBR: bidSelfSchedule !== undefined ? (!isNaN(bidSelfSchedule) ? bidSelfSchedule : -Infinity) : undefined,
        bidSelfScheduleSIBRTime: submittedData?.lastModified,
        bidSelfScheduleScrapeTime: submittedData?.reference_datetime_utc,
        wheelReference: wheelReference || undefined,
      };

      if(bidPriceCurve) {
       for(let i = 0; i < bidPriceCurve.length; i++) {
          const item = bidPriceCurve[i];
          const mw = parseFloat(item.mw);
          const price = parseFloat(item.price);
          resourceBid[`segment${i+1}MW`] = mw;
          resourceBid[`segment${i+1}Price`] = price;
        }
      }

      resourceBids.push(resourceBid);
    }

    const transformed: TransformedData = {
      name: intervals[0].resource,
      nameArray,
      marketType,
      tradeDate,
      resourceType,
      resourceBids
    };

    result.push(transformed);
  }

  // Check for any submitted data that doesn't have a corresponding interval data
  // so that we make sure we're not missing any data
  for(const combinedKey in resourcesMapSubmittedData) {

    const submittedDatas = resourcesMapSubmittedData[combinedKey];

    const firstHR = Object.keys(submittedDatas)[0] as any as number;

    const marketType = submittedDatas[firstHR].marketType;
    const tradeDate = submittedDatas[firstHR].pacificTradeDate.substring(0, 10);
    const resourceType = "";

    // Extract fields from one of the intervals (they should be consistent across intervals of the same resource)
    const {
      schedulingCoordinator,
      Pnode_mrid: location,
      PrimaryFlowgate_mrid: schedulingPoint,
      direction,
      energyProductType: energyType,
      RegisteredInterTie_mrid: resource
    } = submittedDatas[firstHR];


    // The last part of the nameArray is after the last '-'
    const lastDashIndex = resource.lastIndexOf('-');
    const lastValue = lastDashIndex !== -1 ? resource.substring(lastDashIndex + 1) : resource;

    // Construct the nameArray using fields:
    // According to the instructions:
    // 0: schedulingCoordinator
    // 1: location
    // 2: schedulingPoint
    // 3: direction
    // 4: "P" (a fixed letter)
    // 5: first letter of energyType in uppercase
    // 6: the last word after the last '-'
    const nameArray = [
      schedulingCoordinator,
      location,
      schedulingPoint,
      direction,
      "P", // fixed
      energyType.charAt(0).toUpperCase(),
      lastValue
    ];

    const resourceBids: ResourceBid[] = [];

    for(const hrStr in submittedDatas) {

      const hr = parseInt(hrStr, 10);

      const submittedData = submittedDatas[hr];

      let bidSelfSchedule = undefined;
      let bidPriceCurve = undefined;
      let wheelReference = undefined;

      if(submittedData) {
        if(submittedData.wheel) {
          const wheel = submittedData.wheel;
          bidSelfSchedule = wheel.selfScheduleValue;
          wheelReference = wheel.wheelReference;
        } else {
          try{
            // replace all ' in the string with "
            const processed_self_sched_data = submittedData.self_sched_data.replace(/'/g, '"');
            const self_sched_data = JSON.parse(processed_self_sched_data);
            if (self_sched_data.length > 0) {
              bidSelfSchedule = parseFloat(self_sched_data[0].selfSchedMw);
            }
          } catch (e) {
            console.error("Error parsing self_sched_data:", e);
          }
        }

        // bid_price_curve looks like this:
        // [{'timeIntervalEnd': '2025-01-08T03:00:00.000-00:00', 'timeIntervalStart': '2025-01-08T02:00:00.000-00:00', 'mw': '0', 'price': '1.00'}, {'timeIntervalEnd': '2025-01-08T03:00:00.000-00:00', 'timeIntervalStart': '2025-01-08T02:00:00.000-00:00', 'mw': '1', 'price': '1.00'}]

        if(submittedData.bid_price_curve && submittedData.bid_price_curve.length > 0) {
          try {
            const processed_big_price_curve = submittedData.bid_price_curve.replace(/'/g, '"');
            bidPriceCurve = JSON.parse(processed_big_price_curve);
          } catch (e) {
            console.error("Error parsing bid_price_curve:", e);
          }
        }
      }


      // console.log("submittedData", submittedData, "bidSelfSchedule", bidSelfSchedule, "bidPriceCurve", bidPriceCurve);

      const resourceBid: ResourceBid = {
        id: hr,
        hour: hr,
        awardedBid: Array(0),
        awardedSelfSchedule: Array(0),
        lmp: Array(0),
        // segment1MW: 0,
        status: submittedData?.bidStatus,
        errors: submittedData?.errors,
        bidOption: submittedData?.resourceBidOption,
        bidSelfScheduleSIBR: bidSelfSchedule !== undefined ? (!isNaN(bidSelfSchedule) ? bidSelfSchedule : -Infinity) : undefined,
        bidSelfScheduleSIBRTime: submittedData?.lastModified,
        bidSelfScheduleScrapeTime: submittedData?.reference_datetime_utc,
        wheelReference: wheelReference || undefined,
      };

      if(bidPriceCurve) {
       for(let i = 0; i < bidPriceCurve.length; i++) {
          const item = bidPriceCurve[i];
          const mw = parseFloat(item.mw);
          const price = parseFloat(item.price);
          resourceBid[`segment${i+1}MW`] = mw;
          resourceBid[`segment${i+1}Price`] = price;
        }
      }

      resourceBids.push(resourceBid);
    }

    const transformed: TransformedData = {
      name: submittedDatas[firstHR].RegisteredInterTie_mrid,
      nameArray,
      marketType,
      tradeDate,
      resourceType,
      resourceBids
    };

    if(resourcesMap[combinedKey]) {
      const existing = result.find(r => r.name === transformed.name);
      if(existing) {
        for(const rb of transformed.resourceBids) {
          if(!existing.resourceBids.find(r => r.id === rb.id)) {
            existing.resourceBids.push(rb);
          }
        }
      } else {
        console.error("Error: transformed data already exists in result:", transformed);
      }
    } else {
      result.push(transformed);
    }
  }


  for(const resourceBid of result) {
    if(resourceBid.resourceBids.length > 0) {
      let wheelReferenceArray: string[] = [];
      resourceBid.resourceBids.forEach(rb => {
        if(rb.wheelReference) {
          if(wheelReferenceArray.length === 0) {
            const wheelReferenceBidData = result.find(r => r.name === rb.wheelReference);
            if(wheelReferenceBidData) {
              wheelReferenceArray = wheelReferenceBidData.nameArray;
            }
          }
          rb.wheelReferenceArray = wheelReferenceArray;
        }
      });
    }
  }

  console.log("result", result);
  return result;
}


const CaisoBidSubmit = () => {

  const [showSummary, setShowSummary] = useState(true);
  const [selectedDate, setSelectedDate] = useState<Date | null>(dayjs().toDate());
  const [processedData, setProcessedData] = useState<TransformedData[]>([]);
  const [selectedResource, setSelectedResource] = useState<TransformedData | null>(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    handleFetchData(date);
  };
  

  const handleFetchData = async (date?: Date | null) => {
    setLoading(true);
    setErrorMessage("");
    try {

      if (!date || !dayjs(date).isValid() || dayjs(date).year() < 2000) {
        setErrorMessage("Invalid date selected. Please choose a valid date.");
        setLoading(false);
        return;
      }

      const dateString = dayjs(date || new Date()).format("YYYY-MM-DD");
      const apiUrl = `${process.env.REACT_APP_ENDPOINTS_URL}/pull-caiso-awards`;
    
      // POST request to pull caiso awards API
      const response = await fetchGCP(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ date: dateString }),
      });
    
      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`);
      }
    
      // The response should match the shape of IntervalData[]
      const rawData: responseData = await response.json();
      if (!rawData || rawData.length === 0) {
        setProcessedData([]);
        setErrorMessage("No data returned for this date.");
      } else {
        const pd = processData(rawData);
        setProcessedData(pd);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrorMessage(error instanceof Error ? error.message : "An error occurred while fetching data");
    } finally {
      setLoading(false);
    }
  };


  const handleRowClick = (row: TransformedData) => {
    setSelectedResource(row);
    setShowSummary(false);
  };

  const handleBackToSummary = () => {
    setSelectedResource(null);
    setShowSummary(true);
  };

  const handleSubmit = async (data: BidData) => {
    console.log("Data submitted:", data);
    const apiUrl = `${process.env.REACT_APP_ENDPOINTS_URL}/caiso-bids`;

    try {
      const res = await fetchGCP(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (res.ok) {
        console.log("Data submitted successfully", res);
      } else {
        alert("Error submitting data: bad backend response");
        console.error("Error submitting data", res);
      }

    } catch (error) {
      alert(`Error submitting data: ${error instanceof Error ? error.message : 'Unknown error'}`);
      console.error("Error submitting data", error);
    }
  };


  const handleCreateNew = () => {
    setSelectedResource(null);
    setShowSummary(false);
  };

  useEffect(() => {
    // On first load, fetch data immediately
    handleFetchData(selectedDate);
  }, []);

  return (
    <Card>
      <CardContent>
      {showSummary ? (
          <>
            <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
              CAISO Bids Summary
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <DatePicker
                label="Trade Date"
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
              <Button variant="contained" onClick={() => handleFetchData(selectedDate)} sx={{ ml: 3 }}>
                Fetch Data
              </Button>
            </Box>
            
            {loading ? ( // <-- Show loading spinner while loading
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {errorMessage && (
                  <Typography variant="body1" color="error" sx={{ mt: 2 }}>
                    {errorMessage}
                  </Typography>
                )}
                {processedData.length > 0 && (
                  <>
                    <Table sx={{ mt: 2 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Resource Type</TableCell>
                          <TableCell>Market Type</TableCell>
                          <TableCell>Trade Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {processedData.map((row, idx) => (
                          <TableRow 
                            key={idx}
                            hover
                            onClick={() => handleRowClick(row)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.resourceType}</TableCell>
                            <TableCell>{row.marketType}</TableCell>
                            <TableCell>{row.tradeDate}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                )}
                <Button variant="outlined" sx={{ mt: 2 }} onClick={handleCreateNew}>
                  Create New
                </Button>
              </>
            )}
          </>
        ) : (
          <BidMainHeader onSubmit={handleSubmit} initData={selectedResource!} onBack={handleBackToSummary} />
        )}
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};

export default CaisoBidSubmit;
